.App {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 10px;
  box-sizing: border-box;
}

.right-column {
  padding: 10px;
  box-sizing: border-box;
  width: 75%;
}

.bar-box {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.big-text-box {
  width: 100%;
  height: 80%;
  padding: 10px;
  /* box-sizing: border-box;
  font-size: 16px; */
  white-space: now-rap;
  overflow-x: scroll
}

.download-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  background-color: #4CAF50;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.download-button:hover {
  background-color: #45a049;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}